import { h } from 'preact'
import { Router } from 'preact-router'

import FirstPage from './routes/FirstPage/FirstPage'
import SearchPage from './routes/SearchPage/SearchPage'
import AboutPage from './routes/AboutPage/AboutPage'
import CookiePage from './routes/CookiePage/CookiePage'
import BrandsPage from './routes/BrandsPage/BrandsPage'
import ProductsPage from './routes/ProductsPage/ProductsPage'
import GeoareasPage from './routes/GeoareasPage/GeoareasPage'

import './style'

export default () => (
	<Router>
		<FirstPage path="/" />
		<SearchPage path="/list/:query?" mode="list" />
		<SearchPage path="/map/:query?" mode="map" />
		<SearchPage path="/id/:name/:id" />
		<AboutPage path="/about" />
		<CookiePage path="/integritypolicy" />
		<BrandsPage path="/brands" />
		<ProductsPage path="/products" />
		<GeoareasPage path="/geoareas" />
	</Router>
)
