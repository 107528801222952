import { h, Component } from 'preact'
import classNames from 'classnames'

import style from './MapModeSelector.css'

export default class MapModeSelector extends Component {

	setMapMode = (mapMode) => {

			this.props.onChange(mapMode)

	}

	render() {

		/*
						<span
					class={classNames(selected === 'hits' ? style.selected : '')}
					onClick={() => this.setMapMode('hits')}>
					Alla träffar
				</span>				
		*/

		const { selected = 'nisse' } = this.props

		return (
			<div class={style.MapModeSelector}>
				<span
					class={classNames(selected === 'local' ? style.selected : '')}
					onClick={() => this.setMapMode('local')}>
					Häromkring
				</span>
				<span
					class={classNames(selected === 'sweden' ? style.selected : '')}
					onClick={() => this.setMapMode('sweden')}>
					Hela Sverige
				</span>
			</div>
		)
	}
}
