const getCrawledCategoryItems = (lbu) => {
  if (lbu.crawled && lbu.crawled.category && lbu.crawled.category.hits && lbu.crawled.category.hits.length > 0) {
    return lbu.crawled.category.hits
  }
}

const getCrawledBrandItems = (lbu) => {
  if (lbu.crawled && lbu.crawled.brand && lbu.crawled.brand.hits && lbu.crawled.brand.hits.length > 0) {
    return lbu.crawled.brand.hits
  }
}

const getCrawledProductItems = (lbu) => {
  if (lbu.crawled && lbu.crawled.product && lbu.crawled.product.hits && lbu.crawled.product.hits.length > 0) {
    return lbu.crawled.product.hits
  }
}

export const getCrawledItems = (lbu) => {
  if (!lbu) {
    return
  }
  const crawledCategoryItems = getCrawledCategoryItems(lbu)
  if (crawledCategoryItems) {
    return crawledCategoryItems
  }
  const crawledBrandItems = getCrawledBrandItems(lbu)
  if (crawledBrandItems) {
    return crawledBrandItems
  }
  const crawledProductItems = getCrawledProductItems(lbu)
  if (crawledProductItems) {
    return crawledProductItems
  }
}

export const getAllCrawledItems = (lbu) => {
  let all = []
  all = all.concat(getCrawledCategoryItems(lbu) || [])
  all = all.concat(getCrawledBrandItems(lbu) || [])
  all = all.concat(getCrawledProductItems(lbu) || [])
  return all
}

export const getCrawledImage = (lbu) => {
  if (!lbu) {
    return
  }
  if (!lbu.isLBUHit) {
    const items = getCrawledItems(lbu)
    if (items) {
      return items[0].image
    }
  }
}
