import { h, Component } from 'preact'
import { stringify } from 'querystring'
import classNames from 'classnames'

const baseUrl = process.env.PREACT_APP_IMG_URL

const options = {
  root: null,
  rootMargin: '40px',
  threshold: 0.5
}

const useIntersectionObserver = () => {
  return 'IntersectionObserver' in window
}

class Image extends Component {

  state = {
	inView: useIntersectionObserver() ? false : true
  }

  componentDidMount() {
	if (useIntersectionObserver()) {
	  this.observer = new IntersectionObserver(this.intersectionObserverCallback, options)
	  this.observer.observe(this.img)
	}
  }

  componentWillUnmount() {
	if (useIntersectionObserver() && this.img) {
	  this.observer.unobserve(this.img)
	}
  }

  intersectionObserverCallback = (entries, observer) => {
	const inView = entries.some(entry => entry.isIntersecting)
	if (inView !== this.state.inView) {
	  this.setState({ inView })
	}
  }

  render() {
  	const { inView, loadError } = this.state

  	if (loadError) {
  		return null
  	}

	const src = inView ? baseUrl + '?' + stringify({
		url: this.props.url,
		width: 200,
		height: 200
	}) : null

	return (
	  <img
	  	src={src}
		ref={elem => { this.img = elem }}
		onError={() => {
			if (src) {
				console.warn("Load error:", src)
				this.setState({ loadError: true })
			}
		}} />
	)
  }

}

export default Image
