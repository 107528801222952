import { h, Component } from "preact";
import classNames from "classnames";

import loadScript from "../../load-script";
import isSame from "../../is-same";
import { hasGeometry } from "../../has-geometry";

import MyLocationOverlay from "./MyLocationOverlay";
import MyLocationControl from "./MyLocationControl";
import ZoomControl from "./ZoomControl";

import style from "./Map.css";
import mapStyle from "./style.json";

export default class MapComponent extends Component {
  markers = new Map();

  componentDidMount() {
    loadScript(
      "https://maps.googleapis.com/maps/api/js?libraries=geometry&key=AIzaSyCw69a6IO5afiVrnEOOlCZoG_R2x24sM64"
    ).then(() => {
      this.map = new google.maps.Map(this.mapDiv, {
        mapTypeControl: false,
        scaleControl: true,
        zoomControl: false,
        streetViewControl: false,
        fullscreenControl: false
      });

      const styledMapType = new google.maps.StyledMapType(mapStyle);
      this.map.mapTypes.set("styled_map", styledMapType);
      this.map.setMapTypeId("styled_map");

      google.maps.event.addListener(this.map, "idle", () => {
        this.map.setOptions({ maxZoom: null });

        const bounds = this.map.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        const bbox = [sw.lng(), sw.lat(), ne.lng(), ne.lat()].join(",");

        if (this.props.bbox !== bbox) {
          this.props.onChange(bbox);
        }
      });

      google.maps.event.addListener(this.map, "click", () => {
        this.props.hidePreview();
      });

      if (this.props.bbox) {
        const bbox = this.props.bbox.split(",").map(parseFloat);
        const sw = { lat: bbox[1], lng: bbox[0] };
        const ne = { lat: bbox[3], lng: bbox[2] };
        const bounds = new google.maps.LatLngBounds(sw, ne);
        this.map.fitBounds(bounds, 0);
      } else if (this.props.lbus && this.props.lbus.length > 0) {
        this.zoomToExtent();
      } else {
        this.map.setCenter({ lat: 59.332762, lng: 18.070576 });
        this.map.setZoom(13);
      }

      if (this.props.searchMode !== "webshops") {
        this.addMarkers();
      }

      const myLocationControl = new MyLocationControl(this.map);
      myLocationControl.onPosition = latlng => {
        const inView = this.map.getBounds().contains(latlng);

        if (this.myLocationOverlay && inView) {
          this.myLocationOverlay.setMap(null);
          this.myLocationOverlay = null;
          return;
        }
        if (!this.myLocationOverlay) {
          this.myLocationOverlay = new MyLocationOverlay(this.map);
          this.myLocationOverlay.setMap(this.map);
        }
        if (!inView) {
          this.map.setOptions({ maxZoom: 18 });
          this.map.fitBounds(this.getLBUBounds().extend(latlng));
        }
      };

      const zoomControl = new ZoomControl(this.map);

      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        myLocationControl
      );
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        zoomControl
      );
    });
  }

  componentWillUnmount() {
    if (typeof google !== "undefined") {
      google.maps.event.clearInstanceListeners(this.map);
    }
  }

  componentDidUpdate(prevProps) {

    if (this.map && this.props.mapMode !== undefined) {
      if (this.props.mapMode === 'sweden') {
        this.map.setCenter({ lat: 62.8, lng: 15.8 });
        this.map.setZoom(4.2);
      }
      if (this.props.mapMode === 'local') {
        navigator.geolocation.getCurrentPosition(pos => {
          if (pos.coords) {
            console.log('pos', pos)
            this.map.setCenter({ lat: pos.coords.latitude, lng: pos.coords.longitude  });
            this.map.setZoom(12);
          }
        }, null, {
          enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 60000
        })
      }
    }    

    if (this.map && this.props.searchMode === "webshops") {
      this.removeAllMarkers();
      return;
    }

    if (
      this.map &&
      (!isSame(prevProps.lbus, this.props.lbus) ||
        prevProps.selected !== this.props.selected)
    ) {
      this.addMarkers();
      if (!this.props.visible) {
        this.zoomToExtent();
      }
    }
  }

  removeAllMarkers = () => {
    this.markers.forEach((marker, id) => {
      marker.setMap(null);
      this.markers.delete(id);
    });
  };

  addMarkers = () => {
    const splatterIcon = {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 4,
      fillColor: "#D9281E",
      fillOpacity: 1,
      strokeOpacity: 0
    };

    const icon = {
      path:
        "M36.668,16.667C36.668,7.462,29.205,0,19.998,0 C10.793,0,3.332,7.462,3.332,16.667c0,5.407,2.646,9.311,6.579,13.245L20,40l10.091-10.091 C33.861,26.139,36.668,22.073,36.668,16.667z",
      anchor: new google.maps.Point(20, 40),
      labelOrigin: new google.maps.Point(20, 15),
      fillColor: "#D9281E",
      fillOpacity: 1,
      strokeOpacity: 0,
      scale: 1
    };

    const splatterIconSelected = Object.assign({}, splatterIcon, {
      fillColor: "#111111"
    });

    const iconSelected = Object.assign({}, icon, { fillColor: "#111111" });

    const { lbus, selected } = this.props;

    // Remove old markers
    this.markers.forEach((marker, id) => {
      if (!lbus.some(lbu => lbu.id === id)) {
        marker.setMap(null);
        this.markers.delete(id);
      }
    });

    // Add new markers
    lbus.forEach((lbu, index) => {
      if (!hasGeometry(lbu)) {
        return;
      }

      if (this.props.searchMode === "combined") {
        if (!lbu.showAddress) return
      }

      const isDot = index > 4;
      const isSelected =
        this.props.selected != null && this.props.selected.id === lbu.id;

      const oldMarker = this.markers.get(lbu.id);
      if (oldMarker) {
        if (
          isSelected === oldMarker.isSelected &&
          (index === oldMarker.index || (isDot && oldMarker.isDot))
        ) {
          google.maps.event.clearListeners(oldMarker, "click");
          oldMarker.addListener("click", e => this.props.showPreview(lbu));
          return;
        }
        oldMarker.setMap(null);
      }

      const [lng, lat] = lbu.geometry.coordinates;

      const label = {
        color: "white",
        fontFamily: "Helvetica Neue, Arial",
        fontSize: "16px",
        fontWeight: "bold",
        text: "" + (index + 1)
      };

      const marker = new google.maps.Marker({
        position: { lat, lng },
        icon: isDot
          ? isSelected
            ? splatterIconSelected
            : splatterIcon
          : isSelected
          ? iconSelected
          : icon,
        label: isDot ? undefined : label,
        map: this.map,
        title: lbu.name,
        zIndex: isDot ? 0 : 1
      });

      marker.index = index;
      marker.isDot = isDot;
      marker.isSelected = isSelected;

      marker.addListener("click", e => this.props.showPreview(lbu));
      this.markers.set(lbu.id, marker);
    });
  };

  setCenter = (lng, lat, zoom) => {
    this.map.setCenter({ lng, lat });
    if (zoom) {
      this.map.setZoom(zoom);
    }
  };

  getLBUBounds = () => {
    const bounds = new google.maps.LatLngBounds();
    const { lbus } = this.props;
    if (lbus && lbus.length > 0) {
      this.props.lbus.forEach(lbu => {
        if (!hasGeometry(lbu)) {
          return;
        }
        const [lng, lat] = lbu.geometry.coordinates;
        bounds.extend({ lat, lng });
      });
    }
    return bounds;
  };

  zoomToExtent = () => {
    const bounds = this.getLBUBounds();
    if (!bounds.isEmpty()) {
      this.map.setOptions({ maxZoom: 18 });
      this.map.fitBounds(bounds);
    }
  };

  render() {
    const hasPreview = !!this.props.selected;

    return (
      <div
        class={classNames(style.Map, hasPreview ? style.hasPreview : "")}
        ref={div => (this.mapDiv = div)}
      />
    );
  }
}
