import { h, Component } from 'preact'
import { route } from 'preact-router'

import classNames from 'classnames'

import style from './FirstFooter.css'

import { HomeIcon, ListIcon, MapIcon } from '../SVGIcons'

export default class FirstFooter extends Component {

	setMode = (mode) => () => {
		if (mode !== this.props.mode) {
			this.props.onChange(mode)
		}
	}

	render() {

		const { mode } = this.props

		return (
			<div class={style.FirstFooter}>
				<div
					onClick={() => { route('/about') }}>
					<img src="../../assets/about-us.png" height="24" width="24"></img>
					Om MyLBS.cc
				</div>
				<div
					onClick={() => { route('/integritypolicy') }}>
					<img src="../../assets/cookie.png" height="24" width="24"></img>
					Integritetspolicy
				</div>
			</div>
		)
	}
}
