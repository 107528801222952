import { h, Component } from 'preact'
import classNames from 'classnames'

import { getCrawledImage } from '../../get-crawled-items'
import Image from '../Image/Image'

import style from './LBUPreview.css'

import { MapIcon } from '../SVGIcons'

export default class LBUPreview extends Component {

	state = {
		lbu: null,
		visible: false
	}

	componentDidUpdate(prevProps) {
		const { lbu } = this.props

		if (lbu && !prevProps.lbu) {
			this.setState({ lbu })
			setTimeout(() => {
				this.setState({ visible: true })
			}, 0)
		} else if (!lbu && prevProps.lbu) {
			this.setState({ visible: false })
			setTimeout(() => {
				this.setState({ lbu: null })
			}, 200)
		} else if (lbu !== prevProps.lbu) {
			this.setState({ lbu })
		}
	}

	render() {
		const { visible, lbu } = this.state;
		const image = getCrawledImage(lbu);

		return (
			<div
				class={classNames(style.LBUPreview, visible ? style.visible : '')}
				onClick={() => this.props.onClick(lbu)}>
				{lbu && (
					<div>
						<div class={style.text}>
							{lbu.number}. <span class={style.name}>{lbu.name}</span>, <span class={style.address}>{lbu.address.streetName} {lbu.address.streetNumber}, {lbu.address.postalArea}</span>
						</div>
						{ image && (
							<Image class={style.image} url={image} />
						)}
					</div>
				)}
			</div>
		)
	}
}
