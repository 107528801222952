import { h, Component } from "preact";
import { route } from "preact-router";

import classNames from "classnames";
import { parse, stringify } from "querystring";
import { get } from "../../api";
import { getAllCrawledItems } from "../../get-crawled-items";

import {
  BackArrow,
  MapIcon,
  SearchIcon,
  CalendarIcon,
  PhoneIcon
} from "../../components/SVGIcons";
import Address from "./Address/Address";
import CrawledItem from "./CrawledItem/CrawledItem";
import SitemapItem from "./SitemapItem/SitemapItem";

import style from "./LBUDetails.css";

class LBUDetails extends Component {
  state = {
    lbu: undefined,
    lbusearch: undefined,
    lbuquery: undefined,
    lbutranslation: undefined
  };

  AREACODE_RE = /^(010|020|0200|070|072|073|074|076|079|0710|011|0120|0121|0122|0123|0125|013|0140|0141|0142|0143|0144|0150|0151|0152|0155|0156|0157|0158|0159|016|0171|0173|0174|0175|0176|018|019|021|0220|0221|0222|0223|0224|0225|0226|0227|023|0240|0241|0243|0246|0247|0248|0250|0251|0253|0258|026|0270|0271|0278|0280|0281|0290|0291|0292|0293|0294|0295|0297|0300|0301|0302|0303|0304|031|0320|0321|0322|0325|033|0340|0345|0346|035|036|0370|0371|0372|0380|0381|0382|0383|0390|0392|0393|040|0410|0411|0413|0414|0415|0416|0417|0418|042|0430|0431|0433|0435|044|0451|0454|0455|0456|0457|0459|046|0470|0471|0472|0474|0476|0477|0478|0479|0480|0481|0485|0486|0490|0491|0492|0493|0494|0495|0496|0498|0499|0500|0501|0502|0503|0504|0505|0506|0510|0511|0512|0513|0514|0515|0520|0521|0522|0523|0524|0525|0526|0528|0530|0531|0532|0533|0534|054|0550|0551|0552|0553|0554|0555|0560|0563|0564|0565|0570|0571|0573|0580|0581|0582|0583|0584|0585|0586|0587|0589|0590|0591|060|0611|0612|0613|0620|0621|0622|0623|0624|063|0640|0642|0643|0644|0645|0647|0650|0651|0652|0653|0657|0660|0661|0662|0663|0670|0671|0672|0680|0682|0684|0687|0690|0691|0692|0693|0695|0696|08|090|0910|0911|0912|0913|0914|0915|0916|0918|0920|0921|0922|0923|0924|0925|0926|0927|0928|0929|0930|0932|0933|0934|0935|0940|0941|0942|0943|0950|0951|0952|0953|0954|0960|0961|0970|0971|0973|0975|0976|0977|0978|0980|0981)(\d*)/;

  formatPhoneNumber = str => {
    if (!str) {
      return null;
    }
    // Trim
    let s = str.trim();

    if (s.length < 1) {
      return null;
    }
    // Remove hyphens
    s = s.replace(/-/g, "");
    // Remove spaces
    s = s.replace(/ /g, "");
    // Replace country code with 0
    s = s.replace(/^(46|0046|\+46)/, "0");
    // Find area code
    let areaCode = "";
    let number = s;
    let areaAndNumber = s.match(this.AREACODE_RE);
    if (areaAndNumber) {
      areaCode = areaAndNumber[1] + "-";
      number = areaAndNumber[2];
    }
    if (number.length === 5) {
      number = number.substr(0, 3) + " " + number.substr(3, 2);
    } else if (number.length === 6) {
      number =
        number.substr(0, 2) +
        " " +
        number.substr(2, 2) +
        " " +
        number.substr(4, 2);
    } else if (number.length === 7) {
      number =
        number.substr(0, 3) +
        " " +
        number.substr(3, 2) +
        " " +
        number.substr(5, 2);
    } else if (number.length === 8) {
      number =
        number.substr(0, 3) +
        " " +
        number.substr(3, 3) +
        " " +
        number.substr(6, 2);
    }
    return areaCode + number;
  };

  goBack = () => {
    const { mode, bbox, query } = parse(location.search.slice(1));
    const searchMode = this.props.searchMode
    if (mode === "list" || mode === "map") {
      if (bbox && bbox !== "") {
        route("/" + mode + "/" + query + "?" + stringify({ bbox }));
      } else if (searchMode && searchMode !== "") {
        route("/" + mode + "/" + query + "?" + stringify({ searchMode }));
      } else {
        route("/" + mode + "/" + query);
      }
    } else {
      route("/");
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lbu !== this.props.lbu && this.props.lbu) {
      this.setState({
        visible: true,
        lbu: this.props.lbu,
        lbusearch: undefined,
        lbuquery: undefined,
        lbutranslation: undefined
      });
    }

    if (prevProps.lbu && !this.props.lbu) {
      this.setState({
        visible: false,
        lbusearch: undefined,
        lbuquery: undefined,
        lbutranslation: undefined
      });
      setTimeout(() => {
        this.setState({
          lbu: undefined,
          lbusearch: undefined,
          lbuquery: undefined,
          lbutranslation: undefined
        });
      }, 200);
    }
  }

  cancelEnter = e => {
    if (e.keyCode == 13) {
      e.preventDefault();
      return;
    }
  };

  updatedSearchPage = e => {
    const { mode, bbox, query } = parse(location.search.slice(1));
    this.state.lbutranslation =
      this.state.lbu.filteredTranslatedQuery != ""
        ? this.state.lbu.filteredTranslatedQuery
        : undefined;
    if (this.state.lbuquery == undefined) {
      if (this.state.lbu.searchpage.includes("SOMsearch")) {
        this.state.lbuquery = this.state.lbu.searchpage;
        const res = this.state.lbu.searchpage
          .replace(new RegExp("SOMsearch", "g"), e.target.value)
          .replace(/ /g, "+");
        this.state.lbusearch = res;
      } else {
        if (this.state.lbutranslation != undefined) {
          this.state.lbuquery = this.state.lbu.searchpage.replace(
            new RegExp(this.state.lbutranslation, "g"),
            "SOMsearch"
          );
          const res = this.state.lbu.searchpage
            .replace(
              new RegExp(this.state.lbu.filteredQuery, "g"),
              e.target.value
            )
            .replace(/ /g, "+");
          this.state.lbusearch = res;
        } else {
          if (this.state.lbu.filteredQuery != undefined) {
            this.state.lbuquery = this.state.lbu.searchpage.replace(
              new RegExp(this.state.lbu.filteredQuery, "g"),
              "SOMsearch"
            );
            const res = this.state.lbu.searchpage
              .replace(
                new RegExp(this.state.lbu.filteredQuery, "g"),
                e.target.value
              )
              .replace(/ /g, "+");
            this.state.lbusearch = res;
          }
        }
      }
    } else {
      const res = this.state.lbuquery
        .replace(new RegExp("SOMsearch", "g"), e.target.value)
        .replace(/ /g, "+");
      this.state.lbusearch = res;
      // console.log(this.state.lbusearch);
    }
  };

  getSearchpage = e => {
    e.preventDefault();
    const { mode, bbox, query } = parse(location.search.slice(1));
    //console.log(e.target);
    //console.log(this.state.lbusearch)

    if (this.state.lbu.searchpage.includes("SOMsearch")) {
      if (this.state.lbusearch != undefined) {
        window.open(this.state.lbusearch, "_blank");
      } else {
        if (this.state.lbu.filteredTranslatedQuery != undefined) {
          this.state.lbutranslation = this.state.lbu.filteredTranslatedQuery;
          window.open(
            this.state.lbu.searchpage
              .replace(new RegExp("SOMsearch", "g"), this.state.lbutranslation)
              .replace(/ /g, "+"),
            "_blank"
          );
        } else {
          window.open(
            this.state.lbu.searchpage.replace(new RegExp("SOMsearch", "g"), ""),
            "_blank"
          );
        }
      }
    } else {
      if (this.state.lbusearch != undefined) {
        window.open(this.state.lbusearch, "_blank");
      } else {
        window.open(this.state.lbu.searchpage, "_blank");
      }
    }
  };

  render() {


    const { lbu, visible } = this.state;
    const { mode, bbox, query } = parse(location.search.slice(1));

    if (!lbu) {
      return <div class={style.LBUDetails}></div>;
    }

    /* Add http:// to homepage if missing */
    let homepage = lbu.homepage;
    if (lbu.homepage && !lbu.homepage.startsWith("http")) {
      homepage = "http://" + lbu.homepage;
    }
    const crawledItems = getAllCrawledItems(lbu);

    // if (lbu.searchpage.includes("SOMsearch")) {

    // 	lbu.searchpage = lbu.searchpage.replace("SOMsearch", lbu.filteredQuery);

    // }

    // console.log("Search page: " + lbu.searchpage)
    // console.log("Search page: " + this.state.lbusearch)
    // console.log("Filtered query: " + lbu.filteredQuery)
    // console.log("Query: " + query)
    // console.log(lbu.filteredQuery == '')
    // console.log(" : " + lbu.searchpage == '')
    // console.log("Undefined: " + lbu.searchpage == undefined)
    //console.log(lbu.filteredQuery)
    //console.log(query)

    let sitemapItems =
      lbu.crawled &&
      lbu.crawled.sitemaps &&
      lbu.crawled.sitemaps.hits &&
      lbu.crawled.sitemaps.hits.length > 0
        ? lbu.crawled.sitemaps.hits
        : [];
  
    const sitemapLinks = (lbu.sitemapLink && lbu.sitemapLink.hits) ? lbu.sitemapLink.hits : []
  
    //Temp. fix - someone need to clean up here and List as well...
    if (sitemapLinks.length > sitemapItems.length) {
      sitemapItems = sitemapLinks
    }

    return (
      <div class={classNames(style.LBUDetails, visible ? style.visible : "")}>
        <div class={style.back} onClick={this.goBack}>
          <BackArrow /> <span>Tillbaka</span>
        </div>
        <div class={style.LBUcontainer}>
          <div class={style.gridcontainer}>
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <h1 class={style.name}>{lbu.name}</h1>
                </div>
              </div>
              <div class="row">
                {this.props.searchMode !== "webshops" && (
                  <div class="col-3 col-md-2" id={style.logo}>
                    <div class={style.item3}>
                      <a
                        onClick={e => {
                          e.preventDefault();
                          this.props.onAddressClicked(lbu);
                        }}
                        href="javascript:void(0)"
                      >
                        <MapIcon />
                        Karta
                      </a>
                    </div>
                  </div>
                )}
                {this.props.searchMode !== "webshops" && (
                  <div class="col-9 col-md-10" id={style.lm20}>
                    <a
                      class={style.address}
                      onClick={e => {
                        e.preventDefault();
                        this.props.onAddressClicked(lbu);
                      }}
                      href="javascript:void(0)"
                    >
                      <Address lbu={lbu} />
                    </a>
                  </div>
                )}
              </div>
              <div class="row" id={style.phone}>
                {this.props.searchMode !== "webshops" && lbu.phoneNumber && (
                  <div class="col-3 col-md-2" id={style.logo}>
                    <div class={style.item5}>
                      <a
                        href={"tel:" + lbu.phoneNumber}
                        title={
                          "Call " + this.formatPhoneNumber(lbu.phoneNumber)
                        }
                      >
                        <PhoneIcon />
                      </a>
                    </div>
                  </div>
                )}
                {this.props.searchMode !== "webshops" && lbu.phoneNumber && (
                  <div class="col-9 col-md-10" id={style.lm20}>
                    <a
                      href={"tel:" + lbu.phoneNumber}
                      title={"Call " + this.formatPhoneNumber(lbu.phoneNumber)}
                    >
                      {this.formatPhoneNumber(lbu.phoneNumber)}
                    </a>
                  </div>
                )}
              </div>
              {this.props.searchMode !== "webshops" && lbu.openingHours && (
                <div class="row" id={style.tm10}>
                  <div class="calendar col-3 col-md-2">
                    <a
                      class={style.openingHours}
                      href={lbu.openingHours}
                      target="_blank"
                    >
                      <CalendarIcon />
                    </a>
                  </div>
                  <div class="col-9 col-md-10" id={style.lm20}>
                    <a href={lbu.openingHours} target="_blank">
                      Öppettider
                    </a>
                  </div>
                </div>
              )}

              {homepage && (
                <div class="row" id={style.tm10}>
                  <div class="col-12" id={style.left}>
                    <a class={style.homepage} href={homepage} target="_blank">
                      {homepage}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>

          {lbu.searchpage && !lbu.searchpage.includes("SOMsearch") && (
            <div class="row">
              <div
                class="col-12 col-md-12"
                style="margin-top:15px;margin-bottom:15px;font-weight:500px;padding-left:36px; padding-right:36px"
              >
                <form
                  onSubmit={this.getSearchpage}
                  style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap"
                >
                  <div style="margin-right:10px;display:inline">
                    Sök på {lbu.name} efter
                  </div>
                  <div style="display: flex; flex-direction: row; align-items: center">
                    <input
                      type="text"
                      value={lbu.filteredQuery}
                      id={style.queryLBU}
                      onKeyup={this.updatedSearchPage}
                    ></input>
                    <button type="submit" id={style.searchpage}>
                      <div style="display: flex; flex-direction: row; align-items: center">
                        <SearchIcon />
                        Sök
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {lbu.searchpage && lbu.searchpage.includes("SOMsearch") && (
            <div class="row">
              <div
                class="col-12 col-md-12"
                style="margin-top:15px;margin-bottom:15px;font-weight:500px;padding-left:36px; padding-right:36px"
              >
                <form
                  onSubmit={this.getSearchpage}
                  style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap"
                >
                  <div style="margin-right:10px;display:inline">
                    Sök på {lbu.name} efter
                  </div>
                  <div style="display: flex; flex-direction: row; align-items: center">
                    <input
                      type="text"
                      value={lbu.filteredQuery}
                      id={style.queryLBU}
                      onInput={this.updatedSearchPage}
                    ></input>
                    <button type="submit" id={style.searchpage}>
                      <div style="display: flex; flex-direction: row; align-items: center">
                        <SearchIcon />
                        Sök
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {lbu.searchpage &&
            (crawledItems.length > 0 || sitemapItems.length > 0) && (
              <div class="row">
                <div
                  class="col-12 col-md-12"
                  style="font-weight:500px;padding-left:36px; padding-right:36px"
                >
                  Eller leta i listan över produkter som vi har hittat:
                </div>
              </div>
            )}

          {!lbu.searchpage &&
            (crawledItems.length > 0 || sitemapItems.length > 0) && (
              <div class="row">
                <div
                  class="col-12 col-md-12"
                  style="margin-top:15px;font-weight:500px;padding-left:36px; padding-right:36px"
                >
                  Leta i listan över produkter som vi har hittat:
                </div>
              </div>
            )}

          {crawledItems && (
            <ul class={style.crawled}>
              {crawledItems.map((item, index) => (
                <CrawledItem item={item} />
              ))}
            </ul>
          )}
          {sitemapItems && (
            <ul class={style.crawled}>
              {sitemapItems.map((item, index) => {
                return <SitemapItem item={item} />;
              })}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default LBUDetails;
