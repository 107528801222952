import { h, Component } from "preact";
import { route } from "preact-router";

import classNames from "classnames";

import style from "./Header.css";

import { BackArrow } from "../SVGIcons";

export default class Header extends Component {
  render() {
    return (
      <div class={style.Header}>
        <div class="row" style="margin:0px; align-items: center;">
          <div class="col-2">
            <div
              class={style.back}
              onClick={() => {
                route("/");
              }}
            >
              <BackArrow /> <span>Tillbaka</span>
            </div>
          </div>
          <div class="col-6" style="padding: 10px 15px">
            <img src="/assets/mylbs-logo.svg" height="30" />
          </div>
        </div>
      </div>
    );
  }
}
