import { h, Component } from "preact";
import { route } from "preact-router";
import style from "./ProductLinksItem.css";

class ProductLinksItem extends Component {

    state = {
        fold: true
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.query !== this.props.query) {
            this.setState({fold: true});
        }

    }
    setFold = () => {
        this.setState({fold: !this.state.fold});
    }

    capitalize = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1)
    }

    extractLastWords = (text, url) => {

        const words = text.split(" ")
        var lastPart = url.substring(url.lastIndexOf("/"), url.length)
        var res = ""

        //for (var i = words.length; i--; i > 0 ) {
        for (var i = 0;  i < words.length; i++) {
            if (lastPart.indexOf(words[i]) > -1) {
                if (res.indexOf(words[i]) === -1) {
                    res = res + " " + words[i]
                }
            }
        }

        res = res.trim()
        return res.charAt(0).toUpperCase() + res.slice(1)

    }

    checkLinks = (productLinks, query) => {

        var checkedLinks = []

        query = query.toLowerCase()
        query = query.replace("-"," ")
        query = query.replace("å","a")
        query = query.replace("ä","a")
        query = query.replace("ö","o")


        const parts = query.split(" ")
        productLinks.forEach(link => {
            var there = false
            parts.forEach(part => {
                if (link.text.toLowerCase().indexOf(part) > -1) {
                    there = true
                }
            });
            if (there) checkedLinks.push(link)
        })

        return checkedLinks

    }



    getItem = (item, index, checkedLinks) => {

        let text = this.extractLastWords(item.text, item.url)

        if (text.length < 3) {
            text = item.text.charAt(0).toUpperCase() + item.text.slice(1)
        }
        
        if (this.state.fold) {
            if (index < 1) {
                return (
                    <div>
                        <div class={style.text}>{text}</div>
                            <div class={style.url}>
                                <a href={item.url} target="_blank" title={item.url}>
                                {decodeURIComponent(item.url)}
                                </a>
                            </div>
                    </div>
                )
            }
            if (index === 1) {
                return (
                    <div class={style.more} onClick={() => this.setFold()}>
                        Visa fler
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <div>
                        <div class={style.text}>{text}</div>
                        <div class={style.url}>
                            <a href={item.url} target="_blank" title={item.url}>
                            {decodeURIComponent(item.url)}
                            </a>
                        </div>
                    </div>
                    {(index === checkedLinks.length - 1) && (
                        <div class={style.buttomline}>
                            <div class={style.more} onClick={() => this.setFold()}>
                                Visa färre
                            </div>
                            <div class={style.more} onClick={() => this.props.onDetails(this.props.lbu)}>
                                Visa alla
                            </div>
                        </div>
                    )}
                </div>
            )
        }
        
    }

  render() {
    const { productLinks } = this.props;

    const checkedLinks = this.checkLinks(productLinks, this.props.query)

    if (checkedLinks.length > 0) {
        return (
            <div class={style.ProductLinksItem}>
                {this.props.header && (
                    <div class={style.header}>
                        Produkter vi hittat
                   </div>
                )}
                <div>
                    {(checkedLinks && checkedLinks.length > 0) && (
                        <div>
                            {checkedLinks.map((item, index) => {
                                return this.getItem(item, index, checkedLinks)
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    }
  }
}

export default ProductLinksItem;