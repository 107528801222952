import { h, Component } from "preact";
import classNames from "classnames";
import haversine from "haversine";

import { getCrawledItems, getCrawledImage } from "../../../get-crawled-items";
import { hasGeometry } from "../../../has-geometry";
import Address from "../Address/Address";
import Image from "../../Image/Image";
import style from "./ListItem.css";

import ProductLinksItem from "../ProductLinksItem/ProductLinksItem";

const distance = (a, b) => {
  if (!a || !b) {
    return null;
  }
  const dist = haversine(
    { longitude: a[0], latitude: a[1] },
    { longitude: b[0], latitude: b[1] },
    { unit: "meter" }
  );
  if (dist > 1000) {
    return (dist / 1000).toFixed(1) + " km";
  }
  return dist.toFixed(0) + " m";
};

const trimEms = text => {
  return text.replace("<em>", "").replace("</em>", "");
};

/**
 * Returns the highlighted part ('<em>Text</em>')of the given text
 */
const extractHighlightedWord = text => {
  const re = /<em>(.+?)<\/em>/g; // Non-greedy match
  const match = text.match(re);
  if (match) {
    const str = [];
    match.forEach(item => {
      str.push(trimEms(item));
    });
    return str.join(" ");
  }
};

export default class ListItem extends Component {
  state = {
    initialXPos: undefined,
    left: '0px',
    opacity: 1,
    hideItem: false,
    hidden: this.props.hidden
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lbu !== this.props.lbu) {
      this.setState({left: '0px', opacity: 1, hidden: false});
    }
  }

  /**
   * Returns the highlighted word to use in the "bekräftelselänk"
   */
  getHighlightedWord = lbu => {
    // USE:
    // highlight.searchWords
    // highlight.categories
    // highlight.brandNames
    // highlight.productNames
    //
    // SKIP:
    // highlight.address - Never mentioned
    // highlight.names - Never mentioned
    // highlight.lbuTypes - Never mentioned
    //
    const { highlight } = lbu;
    if (highlight) {
      if (highlight.searchWords && highlight.searchWords.length > 0) {
        return extractHighlightedWord(highlight.searchWords[0]);
      }
      if (highlight.categories && highlight.categories.length > 0) {
        return extractHighlightedWord(highlight.categories[0]);
      }
      if (highlight.brandNames && highlight.brandNames.length > 0) {
        return extractHighlightedWord(highlight.brandNames[0]);
      }
      if (highlight.productNames && highlight.productNames.length > 0) {
        return extractHighlightedWord(highlight.productNames[0]);
      }
    }
  };

  getPrice = item => {
    const price = item.price;
    let new_price;
    if (price) {
      if (price.includes("kr")) {
        new_price = parseFloat(price.replace("kr", ""));
      } else {
        new_price = parseFloat(price);
      }
      if (
        !isNaN(new_price) &&
        new_price > 10 &&
        new_price != 2018 &&
        new_price != 2017
      ) {
        return new_price + " SEK";
      }
    }
  };

  showDetails = (lbu) => {
    this.props.onSelect(lbu)
  }

  getDetails = lbu => {
    // highlight.names
    // crawled cartegory
    // crawled brand
    // crawled product
    // highlight.searchWords, address, categories, lbuTypes, brandNames, productNames

    const { highlight } = lbu;

    if (lbu.isLBUHit && lbu.homepage) {
      if (lbu.searchpage && lbu.filteredTranslatedQuery) {
        const link = lbu.searchpage.replace(
          new RegExp("SOMsearch", "g"),
          lbu.filteredTranslatedQuery
        );
        return (
          <div class={style.details}>
            <div class={style.url}>
              <a
                href={link}
                target="_blank"
                title={lbu.name}
                onClick={evt => {
                  evt.stopPropagation();
                }}
              >
                {link}
              </a>
            </div>
          </div>
        );
      } else {
        return (
          <div class={style.details}>
            <div class={style.url}>
              <a
                href={lbu.homepage}
                target="_blank"
                title={lbu.name}
                onClick={evt => {
                  evt.stopPropagation();
                }}
              >
                {lbu.homepage}
              </a>
            </div>
          </div>
        );
      }
    }

    const items = getCrawledItems(lbu);
    if (items && lbu.searchpage) {
      const item = items[0];
      const link = lbu.searchpage.replace(
        new RegExp("SOMsearch", "g"),
        lbu.filteredTranslatedQuery
      );
      return (
        <div class={style.details} onClick={() => window.open(link)}>
          <div class={style.title}>{item.title}</div>
          <div class={style.title}>{this.getPrice(item)}</div>
          <div class={style.url}>
            <a
              href={link}
              target="_blank"
              title={item.title}
              onClick={evt => {
                evt.stopPropagation();
              }}
            >
              {link}
            </a>
          </div>
        </div>
      );
    }
    if (items && !lbu.searchpage) {
      const item = items[0];
      return (
        <div class={style.details}>
          <div class={style.title}>{item.title}</div>
          <div class={style.title}>{this.getPrice(item)}</div>
          <div class={style.url}>
            <a
              href={item.url}
              target="_blank"
              title={item.title}
              onClick={evt => {
                evt.stopPropagation();
              }}
            >
              {item.url}
            </a>
          </div>
        </div>
      );
    }

    const highlightedWord = this.getHighlightedWord(lbu);
    let replacedSearchPage = "";
    let q = lbu.filteredQuery || this.props.query;
    const tempSearchPage = lbu.searchpage;
    const sitemapItems = (lbu.sitemapLink && lbu.sitemapLink.hits) ? lbu.sitemapLink.hits : []

    if (lbu.combinedGeoSearch.length > 0) {
      const geo = lbu.combinedGeoSearch.replace('+', ' ')
      lbu.filteredQuery = lbu.filteredQuery.replace(geo.trim(), '').trim()
      q = q.replace(geo.trim(), '').trim()
    }

    if (tempSearchPage && q) {
      replacedSearchPage = tempSearchPage.replace(
        new RegExp("SOMsearch", "g"),
        q
      );
    }

    var header = replacedSearchPage ? false : true

    if (highlightedWord && lbu.homepage) {
      return (
        <div>
          <div
            class={style.details}
            onClick={() => window.open(replacedSearchPage)}
          >
            <div class={style.url}>
              <a
                href={lbu.homepage}
                target="_blank"
                title={lbu.homepage}
                onClick={evt => {
                  evt.stopPropagation();
                }}
              >
              {lbu.homepage}
              </a>
            </div>

            {replacedSearchPage && (
              <div>
                <div class={style.header}>
                  Produkter vi hittat
                </div>
                <div class={style.search}>
                  Alla <em>{lbu.filteredQuery}</em> hos {lbu.name}
                </div>
                <div class={style.url}>
                  <a
                    href={replacedSearchPage}
                    target="_blank"
                    title={replacedSearchPage}
                    onClick={evt => {
                      evt.stopPropagation();
                    }}
                  >
                    {replacedSearchPage}
                  </a>
                </div>
              </div>
            )}
          </div>

          <ProductLinksItem
            query={this.props.query}
            lbu={lbu}
            onDetails={this.showDetails}
            productLinks={sitemapItems}
            header={header}
             />
        </div>
      );
    }
    if (highlightedWord && !lbu.homepage) {
      return (
        <div>

          <div class={style.url}>
            <a
              href={lbu.homepage}
              target="_blank"
              title={lbu.homepage}
              onClick={evt => {
                evt.stopPropagation();
              }}
            >
            {lbu.homepage}
            </a>
          </div>

          {replacedSearchPage && (
            <div>
              <div class={style.header}>
                Produkter vi hittat
              </div>
              <div class={style.search}>
                Alla <em>{lbu.filteredQuery}</em> hos {lbu.name}
              </div>
              <div class={style.url}>
                <a
                  href={replacedSearchPage}
                  target="_blank"
                  title={replacedSearchPage}
                  onClick={evt => {
                    evt.stopPropagation();
                  }}
                >
                  {replacedSearchPage}
                </a>
              </div>
            </div>
          )}
          <ProductLinksItem
            query={this.props.query}
            lbu={lbu}
            onDetails={this.showDetails}
            productLinks={sitemapItems}
            header={header}/>
        </div>
      );
    }
    const searchURL = lbu.searchpage
      ? lbu.searchpage.replace("SOMsearch", lbu.filteredQuery)
      : undefined

    header = searchURL ? false : true

    return (
      <div class={style.details}>

        <div class={style.url}>
          <a
            href={lbu.homepage}
            target="_blank"
            title={lbu.homepage}
            onClick={evt => {
              evt.stopPropagation();
            }}
          >
          {lbu.homepage}
          </a>
        </div>

        {searchURL && (
            <div>
              <div class={style.header}>
                Produkter vi hittat
              </div>
              <div class={style.search}>
                Alla <em>{lbu.filteredQuery}</em> hos {lbu.name}
              </div>
              <div class={style.url}>
                <a
                  href={searchURL}
                  target="_blank"
                  title={searchURL}
                  onClick={evt => {
                    evt.stopPropagation();
                  }}
                >
                  {searchURL}
                </a>
              </div>
            </div>
          )}

        <ProductLinksItem
            query={this.props.query}
            lbu={lbu}
            onDetails={this.showDetails}
            productLinks={sitemapItems}
            header={header}/>
      </div>
    );
  };

  showAddress = (lbu) => {

    if (this.props.searchMode === "combined") {
      if (lbu.showAddress) {
        return true
      }
      return false 
    }

    if (this.props.searchMode === "webshops") return false

    if (lbu.geometry) return true

    return false
  };

  showDistance = () => {
    return this.props.searchMode === "distance";
  };

  hideItem = () => {
    this.props.onHideItem(this.props.lbu.id, this.props.searchMode);
  }

  handleTouchStart = (e) => {
    const initialXPos = e.touches[0].screenX;
    this.setState({initialXPos: e.touches[0].screenX});
  };

  handleTouchMove = (e) => {
    const xPos = e.touches[0].screenX;

    let left = Math.min(xPos - this.state.initialXPos, 0);
    let d = Math.abs(left);
    let opacity = 1;

    if (d > 10)
    {
      this.setState({left: `${left}px`});
    }

    if (d > 100 && left < 0)
    {
      opacity = d / 1000;
      this.setState({hideItem:true});
    }
    else {
      this.setState({hideItem:false});
    }

    this.setState({opacity: opacity});
  };

  handleTouchEnd = (e) => {
    if (this.state.hideItem)
    {
      this.setState({left: '-100%'});
      setTimeout(() => {
        this.setState({hidden: true});
      }, 300);

      this.hideItem();
    }
    else {
      this.setState({left: '0px', opacity: 1, hidden: false});
    }
  };

  render() {
    const { lbu, i, lnglat, searchMode } = this.props;

    const dist =
      this.showDistance() && hasGeometry(lbu)
        ? distance(lnglat, lbu.geometry.coordinates)
        : undefined;
    const image = getCrawledImage(lbu);
    const details = this.getDetails(lbu);

    return (
      <div class={classNames(
          style.ListItem,
          style.card,
          (this.state.hidden || this.props.filteredItems.includes(this.props.lbu.id)) ? style.hidden : ""
        )}
        style={{
          left: this.state.left,
          opacity: this.state.opacity
        }}
        onTouchStart={(event) => this.handleTouchStart(event)}
        onTouchMove={(event) => this.handleTouchMove(event)}
        onTouchEnd={(event) => this.handleTouchEnd(event)}
      >
        <li>
          <div>
            <div class={style.number} onClick={() => this.props.onSelect(lbu)}>
              {i + 1}.
            </div>
            <div class={style.name} onClick={() => this.props.onSelect(lbu)}>
              {lbu.name} <span class={style.dist}>{dist}</span>
            </div>
            <div onClick={() => this.props.onSelect(lbu)}>
              {this.showAddress(lbu) && <Address lbu={lbu} />}
            </div>

            {this.showAddress(lbu) && (
              <div>
                {lbu.openingHours && (
                  <span>
                    <a target="_blank" href={lbu.openingHours}>
                      Öppettider
                    </a>
                    &nbsp;·&nbsp;
                  </span>
                )}
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    this.props.onShowMapClicked(lbu);
                  }}
                >
                  Karta
                </a>
              </div>
            )}
            {details}
          </div>
          {image && (
            <div class={style.image}>
              <Image url={image} />
            </div>
          )}
        </li>
      </div>
    );
  }
}
