const getBriefText = (text, length = 115) => {
	if (!text) {
		return
	}
	if (text.length > length) {
		return text.substring(0, length - 2).trim().concat('…')
	}
	return text
}

export default getBriefText
