import { h, Component } from "preact";
import { route } from "preact-router";

import { parse, stringify } from "querystring";
import classNames from "classnames";
import { get } from "../../api";

import SearchBox from "../../components/SearchBox/SearchBox";
import SearchModeSelector from "../../components/SearchModeSelector/SearchModeSelector";
import MapModeSelector from "../../components/MapModeSelector/MapModeSelector";
import List from "../../components/List/List";
import Map from "../../components/Map/Map";
import LBUPreview from "../../components/LBUPreview/LBUPreview";
import LBUDetails from "../../components/LBUDetails/LBUDetails";
import Footer from "../../components/Footer/Footer";

import style from "./SearchPage.css";

export default class SearchPage extends Component {
  state = {
    lbus: null,
    lbu: undefined,
    searchMode: undefined, // 'default', 'distance', 'webshops', 'combined' (default and webshops)
    webshops: false,
    combined: false,
    mapMode: undefined,   //'haromkring', 'hela sverige'
    defaultFilters: [],
    distanceFilters: [],
    webshopsFilters: [],
    combinedFilters: [],
    filteredItems: [],
    forcedToCombined: false
  };

  componentDidMount() {
    const qs = parse(location.search.slice(1));
    const params = {};
    if (qs.bbox && qs.bbox.trim() !== "") {
      params.bbox = qs.bbox.trim();
    }
    if (
      qs.searchMode &&
      (qs.searchMode === "default" ||
        qs.searchMode === "combined" ||
        qs.searchMode === "distance" ||
        qs.searchMode === "webshops")
    ) {
      params.searchMode = qs.searchMode;
    } else {
      params.searchMode === "default";
    }

    if (Object.keys(params).length > 0) {
      this.setState(params);
    } else {
      this.doSearch();
    }

    let filters = {};
    ['default', 'distance', 'webshops', 'combined'].forEach( mode => {
      if (qs[`${mode}Filters`]) {
        filters[`${mode}Filters`] = atob(qs[`${mode}Filters`]).split(',');
      }
    });
    if (Object.keys(filters).length > 0) {
      this.setState(filters);
    }

    if (this.props.id) {
      if (!this.state.lbu) {
        // Fetch base LBU propertie
        get("/lbus/" + this.props.id, { q: this.props.query }).then(lbu => {
          this.setState({ lbu });
          // Fetch crawl/query specific LBU properties
          this.fetchDetailedLBU(this.props.id, lbu.filteredQuery, lbu);
        });
      } else {
        // Fetch crawl/query specific LBU properties
        this.fetchDetailedLBU(
          this.props.id,
          this.state.lbu.filteredQuery,
          this.state.lbu
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.query !== prevProps.query ||
      this.state.bbox !== prevState.bbox ||
      this.state.searchMode !== prevState.searchMode
    ) {
      if ((this.state.searchMode === 'combined' || this.state.searchMode === 'webshops') && prevState.searchMode === 'combined') {
        this.setState({forcedToCombined: false});
      }
      this.doSearch();
    }

    if ((!this.state.searchMode || this.state.searchMode === 'default') && this.state.lbus && this.state.lbus.length === 0 && !this.state.isSearching) {
      this.setState({
        searchMode: 'combined',
        combined: true,
        forcedToCombined: true
      });
    }

    // Reset fitlers so that all ListItems are visible
    if (this.props.query !== prevProps.query) {
      let filters = {
        filteredItems: []
      };
      ['default', 'distance', 'webshops', 'combined'].forEach( mode => {
          filters[`${mode}Filters`] = [];
      });
      if (Object.keys(filters).length > 0) {
        this.setState(filters);
      }
    }

    if (this.props.id && this.props.id !== prevProps.id) {
      if (!this.state.lbu) {
        // Fetch base LBU properties
        get("/lbus/" + this.props.id, { q: this.props.query }).then(lbu => {
          this.setState({ lbu });
          // Fetch crawl/query specific LBU properties
          this.fetchDetailedLBU(this.props.id, lbu.filteredQuery, lbu);
        });
      } else {
        // Fetch crawl/query specific LBU properties
        this.fetchDetailedLBU(
          this.props.id,
          this.state.lbu.filteredQuery,
          this.state.lbu
        );
      }
    } else if (prevProps.id && !this.props.id) {
      this.setState({ lbu: undefined });
    }
  }

  doSearch = () => {
    let query = this.props.query;
    if (query) {
      query = query.trim();
    }

    const params = {
      q: query,
      limit: 200,
      webshops: this.state.searchMode === "webshops",
      combined: this.state.searchMode === "combined"
    };

    if (this.state.bbox && this.state.bbox !== "") {
      params.bbox = this.state.bbox;
    }

    if (this.state.searchMode === "distance") {
      navigator.geolocation.getCurrentPosition(
        pos => {
          if (pos.coords) {
            const lnglat = [pos.coords.longitude, pos.coords.latitude];
            params.lnglat = lnglat.join(",");
            this.fetchLBUs(params);
            this.setState({ lnglat });
          }
        },
        null,
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 60000
        }
      );
    } else {
      this.fetchLBUs(params);
    }
  };

  fetchLBUs = params => {
    this.setState({ isSearching: true });
    get("/search", params)
      .then(lbus => {
        this.setState({
          lbus: lbus
            //.filter(lbu => lbu.geometry && lbu.geometry.coordinates.length === 2)
            .map((lbu, index) => {
              lbu.number = index + 1;
              return lbu;
            })
        })
      }
      )
      .finally(() => {
        this.setState({ isSearching: false });
        this.updateFilteredItems(this.state.searchMode);
      });
  };

  fetchDetailedLBU = (id, query, lbu) => {
    if (query) {
      get("/search/" + id + "?q=" + query.trim()).then(crawled => {
        this.setState({ lbu: Object.assign({}, lbu, { crawled }) });
      });
    }
  };

  showDetails = lbu => {
    this.setState({ lbu });
    const name = lbu.name.replace(/\W+/g, "-").toLowerCase();
    const params = {
      mode: this.props.mode,
      query: this.props.query,
    };
    if (this.state.searchMode && this.state.searchMode !== "") {
      params.searchMode = this.state.searchMode
    }
    if (this.state.bbox && this.state.bbox !== "") {
      params.bbox = this.state.bbox;
    }
    route("/id/" + name + "/" + lbu.id + "?" + stringify(params));
  };

  showLBUonMap = lbu => {
    route(
      "/map/" +
        encodeURIComponent(
          lbu.name +
            ", " +
            lbu.address.streetName +
            " " +
            lbu.address.streetNumber +
            ", " +
            lbu.address.postalArea
        )
    );
    const [lng, lat] = lbu.geometry.coordinates;
    this.map.setCenter(lng, lat, 15);
  };

  setMode = mode => {

    if (mode === "list") this.setState({ mapMode: undefined });

    if (mode === 'map' && this.state.searchMode === 'webshops') this.setState({searchMode: 'default'})

    route("/" + mode + "/" + this.props.query + location.search);
  };

  setQuery = query => {
    const { mode } = this.props;
    const searchMode = this.state.searchMode
    if (mode === "list") {
      this.setState({ bbox: null });
      if (searchMode && searchMode !== "") {
        route("/" + mode + "/" + query + "?" + stringify({ searchMode }));
      } else {
        route("/" + mode + "/" + query );
      }
    } else {
      /* Close preview when query is updated */
      this.setState({ preview: undefined });
      route("/" + mode + "/" + query + location.search);
    }
  };

  setBbox = bbox => {
    if (this.props.mode === "map") {
      this.setState({ mapMode: undefined });
      this.setState({ bbox });
      const params = parse(location.search.slice(1));
      params.bbox = bbox;
      history.replaceState(null, null, "?" + stringify(params));
    }
  };


  setMapMode = mapMode => {
    this.setState({ mapMode });
  };

  setSearchMode = searchMode => {
    this.setState({ searchMode });
    const params = parse(location.search.slice(1));
    params.searchMode = searchMode;
    history.replaceState(null, null, "?" + stringify(params));
  };

  setLbuFilters = (lbuId, searchMode) => {
    let stateKey = (searchMode) ? `${searchMode}Filters` : 'defaultFilters';
    this.setState(state => {
      return {
        stateKey: state[stateKey].push(lbuId)
      }
    }, () => {
      const params = parse(location.search.slice(1));
      params[stateKey] = btoa(this.state[stateKey]);
      history.replaceState(null, null, "?" + stringify(params));
    })
  };

  updateFilteredItems = (searchMode) => {
    let stateKey = (searchMode) ? `${searchMode}Filters` : 'defaultFilters';
    this.setState(state => {
      return {
        filteredItems: state[stateKey]
      }
    })
  };

  render() {
    const { mode, query } = this.props;
    const {
      bbox,
      preview,
      lbu,
      isSearching,
      searchMode = "default"
    } = this.state;

    let lbus = this.state.lbus;

    if (searchMode === "webshops" && lbus !== null) {
      // Filter out duplicate LBUs (same name and url) if webshops is selected
      const uniqueLBUs = [];
      lbus.forEach(lbu => {
        if (!uniqueLBUs.some(l => l.name === lbu.name && l.url === lbu.url)) {
          uniqueLBUs.push(lbu);
        }
      });
      lbus = uniqueLBUs;
    }


    return (
      <div class={classNames(style.SearchPage, style[mode])}>
        <div class={style.Header}>
          <a href="/">
            <img class={style.Logo} src="/assets/mylbs-badge-white-small.svg" height="40" />
          </a>
          <div class={style.SearchBoxWrapper}>
            <SearchBox query={query} onChange={this.setQuery} />
          </div>
          {mode === "map" ? (
            <MapModeSelector
              selected={this.state.mapMode}
              onChange={this.setMapMode}
            />
          ) : (
            <SearchModeSelector
              selected={this.state.searchMode}
              onChange={this.setSearchMode}
            />
          )}
        </div>
        <div class={style.Main}>
          <Map
            searchMode={this.state.searchMode}
            mapMode={this.state.mapMode}
            ref={map => {
              this.map = map;
            }}
            lbus={lbus || []}
            selected={preview}
            bbox={bbox}
            onChange={this.setBbox}
            visible={mode === "map"}
            showPreview={lbu => this.setState({ preview: lbu })}
            hidePreview={() => {
              this.setState({ preview: null });
            }}
          />
          <LBUPreview lbu={preview} onClick={this.showDetails} />
          <List
            query={query}
            searchMode={this.state.searchMode}
            setSearchMode={this.setSearchMode}
            isSearching={isSearching}
            lbus={lbus}
            lnglat={this.state.lnglat}
            visible={mode === "list"}
            filteredItems={this.state.filteredItems}
            onSelect={this.showDetails}
            onHideItem={this.setLbuFilters}
            onShowMapClicked={this.showLBUonMap}
            forcedToCombined={this.state.forcedToCombined}
          />
        </div>
        <Footer mode={mode} onChange={this.setMode} />
        <LBUDetails
          searchMode={this.state.searchMode}
          lbu={lbu}
          onAddressClicked={this.showLBUonMap}
        />
      </div>
    );
  }
}
