import { h, Component } from 'preact'
import classNames from 'classnames'

import style from './SearchModeSelector.css'

export default class SearchModeSelector extends Component {

	setSearchMode = (searchMode) => {
		if (searchMode !== this.props.selected) {
			this.props.onChange(searchMode)
		}
	}
	
	render() {

		const { selected = 'default' } = this.props

		return (
			<div class={style.SearchModeSelector}>
				<span
					class={classNames(selected === 'default' ? style.selected : '')}
					onClick={() => this.setSearchMode('default')}>
					Butiker
				</span>
				<span
					class={classNames(selected === 'webshops' ? style.selected : '')}
					onClick={() => this.setSearchMode('webshops')}>
					E-handel
				</span>
				<span
					class={classNames(selected === 'combined' ? style.selected : '')}
					onClick={() => this.setSearchMode('combined')}>
					B & E
				</span>
				<span
					class={classNames(selected === 'distance' ? style.selected : '')}
					onClick={() => this.setSearchMode('distance')}>
					Nära mig
				</span>
			</div>
		)
	}
}
