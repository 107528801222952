import { h, Component } from "preact";

import Header from "../../components/Header/Header";
import { get } from "../../api";

export default class CookiePage extends Component {
  state = {
    content: ""
  };

  componentDidMount() {
    get("/cms/integrity-policy").then(res =>
      this.setState({ content: res.html })
    );
  }

  render() {
    return (
      <div>
        <Header />
        <div style="position:fixed;top:65px;right:10px;left:10px;bottom:10px;text-align: left;padding:20px;overflow-y:auto;overflow-x:hidden;">
          <div class="row">
            <div
              class="col-md-8  offset-md-2"
              dangerouslySetInnerHTML={{
                __html: this.state.content
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
