import { h } from 'preact'

import style from './Address.css'

const Address = ({lbu}) => {

  if (!lbu || !lbu.address) {
    return
  }

  const {
    streetName,
    streetNumber,
    level,
    postalCode,
    postalArea
  } = lbu.address

  const row1 = []
  if (streetName) {
    row1.push(streetName)
    if (streetNumber) {
      row1.push(streetNumber)
    }
  }

  const row2 = []
  if (level) {
    row2.push(level)
  }
  if (postalArea) {
    row2.push(postalArea)
  }

  return (
    <div class={style.Address}>
      { row1.length > 0 && (
        <div>
          {row1.join(' ')}
        </div>
      )}
      { row2.length > 0 && (
        <div>
          {row2.join(', ')}
        </div>
      )}
    </div>
  )

}

export default Address
