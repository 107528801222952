const ZoomControl = (map) => {
	const controlDiv = document.createElement('div')
	controlDiv.id = 'ZoomControl'

	const zoomIn = document.createElement('div')
	controlDiv.appendChild(zoomIn)

	const zoomOut = document.createElement('div')
	zoomOut.id = 'ZoomOut'
	controlDiv.appendChild(zoomOut)

	zoomIn.innerHTML = `
		<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
		    <path d="M0 0h24v24H0z" fill="none"/>
		</svg>`

	zoomOut.innerHTML = `
		<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		    <path d="M19 13H5v-2h14v2z"/>
		    <path d="M0 0h24v24H0z" fill="none"/>
		</svg>`

	google.maps.event.addDomListener(zoomIn, 'click', () => {
		map.setZoom(map.getZoom() + 1)
	})

	google.maps.event.addDomListener(zoomOut, 'click', () => {
		map.setZoom(map.getZoom() - 1)
	})

	return controlDiv
}

export default ZoomControl
