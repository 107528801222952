import { h, Component } from "preact";
import classNames from "classnames";

import isSame from "../../is-same";
import style from "./List.css";

import ListItem from "./ListItem/ListItem";

export default class List extends Component {
  state = {
    visible: this.props.visible
  };

  isEmpty = obj => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  showList = () => {
    this.setState({ animating: true });
    setTimeout(() => {
      if (this.state.scrollTop) {
        this.list.scrollTop = 0;
      }
      this.setState({ visible: true, scrollTop: false });
    }, 0);
  };

  hideList = () => {
    this.setState({ visible: false });
    setTimeout(() => {
      this.setState({ animating: false });
    }, 200);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.list && prevProps.lbus !== this.props.lbus) {
      if (
        prevProps.lbus &&
        prevProps.lbus.length > 0 &&
        this.props.lbus &&
        this.props.lbus.length > 0 &&
        !isSame(prevProps.lbus, this.props.lbus)
      ) {
        if (this.props.visible) {
          // Scroll to top right away, if possible, ie. the List is visible
          this.list.scrollTop = 0;
        } else {
          // Remember to scroll to top
          // This is needed if the List is display: none at the moment
          this.setState({ scrollTop: true });
        }
      }
    }

    if (!prevProps.visible && this.props.visible) {
      this.showList();
    }

    if (prevProps.visible && !this.props.visible) {
      this.hideList();
    }
  }

  render() {
    const { lbus, lnglat, searchMode, setSearchMode, isSearching } = this.props;
    const { animating, visible } = this.state;

    return (
      <div
        class={classNames(
          style.List,
          animating ? style.animating : "",
          visible ? style.visible : "",
          isSearching ? style.searching : ""
        )}
      >
        <div>
            {this.props.forcedToCombined && lbus && lbus.length > 0 && (
              <div  id={style.forced}>
                Vi hittade inga fysiska butiker men de här online-butikerna hittade vi:
              </div>
            )}
          <ol
            ref={elem => {
              this.list = elem;
            }}
          >
            {lbus &&
              lbus.map((lbu, i) => {
                return <ListItem
                  lbu={lbu}
                  i={i}
                  lnglat={lnglat}
                  filteredItems={this.props.filteredItems}
                  query={this.props.query}
                  searchMode = {searchMode}
                  onSelect={this.props.onSelect}
                  onHideItem={this.props.onHideItem}
                  onShowMapClicked={this.props.onShowMapClicked}
                />;
              })}
            {lbus && lbus.length === 0 && this.props.query !== "" && (
              <div
                class="row"
                id="error-message"
                style="position:absolute;top: 50%;left:50%;z-index:0;transform:translateX(-50%) translateY(-50%);"
              >
                <div class="col-12" id={style.noresults}>
                {searchMode === "combined" && (
                    <p>
                      MyLBS.cc hittar inga fysiska eller online-butiker för den här sökningen.
                    </p>
                  )}
                  {searchMode === "webshops" && (
                    <p>
                      MyLBS.cc hittar inga online-butiker för den här sökningen.
                      Prova att klicka på{" "}
                      <a
                        href="?searchMode=default"
                        onClick={e => {
                          e.preventDefault();
                          setSearchMode("default");
                        }}
                      >
                        Butiker
                      </a>{" "}
                      för att se fysiska butiker.
                    </p>
                  )}
                  {searchMode !== "webshops" || searchMode !== "combined "&& (
                    <p>
                      MyLBS.cc hittar inga fysiska butiker för den här
                      sökningen. Prova att klicka på{" "}
                      <a
                        href="?searchMode=webshops"
                        onClick={e => {
                          e.preventDefault();
                          setSearchMode("webshops");
                        }}
                      >
                        E-handel
                      </a>{" "}
                      för att se online-butiker.
                    </p>
                  )}
                </div>
              </div>
            )}
          </ol>
        </div>
      </div>
    );
  }
}
