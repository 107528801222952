import { h, Component } from 'preact'
import { route } from 'preact-router'

import classNames from 'classnames'
import { get } from '../../api'

import {BackArrow} from '../../components/SVGIcons'

import style from './GeoareasPage.css'
import Header from '../../components/Header/Header'

export default class GeoareasPage extends Component {


	state = {
		"geoareas*": [],
		"geoareasA": [],
		"geoareasB": [],
		"geoareasC": [],
		"geoareasD": [],
		"geoareasE": [],
		"geoareasF": [],
		"geoareasG": [],
		"geoareasH": [],
		"geoareasI": [],
		"geoareasJ": [],
		"geoareasK": [],
		"geoareasL": [],
		"geoareasM": [],
		"geoareasN": [],
		"geoareasO": [],
		"geoareasP": [],
		"geoareasQ": [],
		"geoareasR": [],
		"geoareasS": [],
		"geoareasT": [],
		"geoareasU": [],
		"geoareasV": [],
		"geoareasW": [],
		"geoareasX": [],
		"geoareasY": [],
		"geoareasZ": [],
		"geoareasÅ": [],
		"geoareasÄ": [],
		"geoareasÖ": []

	}

	componentDidMount() {
		this.fetchGeoareas()
	}



	fetchGeoareas = () => {
		get('/geoareas').then(geoareas => this.setState({
			"geoareas*": geoareas["*"],
			"geoareasA": geoareas["a"],
			"geoareasB": geoareas["b"],
			"geoareasC": geoareas["c"],
			"geoareasD": geoareas["d"],
			"geoareasE": geoareas["e"],
			"geoareasF": geoareas["f"],
			"geoareasG": geoareas["g"],
			"geoareasH": geoareas["h"],
			"geoareasI": geoareas["i"],
			"geoareasJ": geoareas["j"],
			"geoareasK": geoareas["k"],
			"geoareasL": geoareas["l"],
			"geoareasM": geoareas["m"],
			"geoareasN": geoareas["n"],
			"geoareasO": geoareas["o"],
			"geoareasP": geoareas["p"],
			"geoareasQ": geoareas["q"],
			"geoareasR": geoareas["r"],
			"geoareasS": geoareas["s"],
			"geoareasT": geoareas["t"],
			"geoareasU": geoareas["u"],
			"geoareasV": geoareas["v"],
			"geoareasW": geoareas["w"],
			"geoareasX": geoareas["x"],
			"geoareasY": geoareas["y"],
			"geoareasZ": geoareas["z"],
			"geoareasÅ": geoareas["å"],
			"geoareasÄ": geoareas["ä"],
			"geoareasÖ": geoareas["ö"]
		}))
	}

	getGeoareasItem = (geoarea) => {
		return (
			<p>
				<a href={this.searchGeoarea(geoarea)}>{this.setCapitalFirst(geoarea)}</a>
			</p>
		)
	}

	setCapitalFirst = (word) => {
		return word[0].toUpperCase() + word.substr(1);
	}

	searchGeoarea = (geoarea) => {
		return '/list/' + encodeURIComponent(geoarea);
	}

	render() {

		let geoareas_ = this.state["geoareas*"]
		let geoareasA = this.state["geoareasA"]
		let geoareasB = this.state["geoareasB"]
		let geoareasC = this.state["geoareasC"]
		let geoareasD = this.state["geoareasD"]
		let geoareasE = this.state["geoareasE"]
		let geoareasF = this.state["geoareasF"]
		let geoareasG = this.state["geoareasG"]
		let geoareasH = this.state["geoareasH"]
		let geoareasI = this.state["geoareasI"]
		let geoareasJ = this.state["geoareasJ"]
		let geoareasK = this.state["geoareasK"]
		let geoareasL = this.state["geoareasL"]
		let geoareasM = this.state["geoareasM"]
		let geoareasN = this.state["geoareasN"]
		let geoareasO = this.state["geoareasO"]
		let geoareasP = this.state["geoareasP"]
		let geoareasQ = this.state["geoareasQ"]
		let geoareasR = this.state["geoareasR"]
		let geoareasS = this.state["geoareasS"]
		let geoareasT = this.state["geoareasT"]
		let geoareasU = this.state["geoareasU"]
		let geoareasV = this.state["geoareasV"]
		let geoareasW = this.state["geoareasW"]
		let geoareasX = this.state["geoareasX"]
		let geoareasY = this.state["geoareasY"]
		let geoareasZ = this.state["geoareasZ"]
		let geoareasÅ = this.state["geoareasÅ"]
		let geoareasÄ = this.state["geoareasÄ"]
		let geoareasÖ = this.state["geoareasÖ"]

		return(
			<div style="overflow:hidden">
			<Header />
			<div class={classNames(style.GeoareasPage)}>
			<h1>Hitta produkter från olika varumärken i Stockholm City</h1>
			<h2>Här kan du hitta de butiker som säljer produkterna från de varumärken du letar efter</h2>
			<div class="letter-list">
				<h3>1-9</h3>
				<div class={style.list}>
					{ geoareas_.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>A</h3>
				<div class={style.list}>
					{ geoareasA.map((geoarea) => {
					return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>B</h3>
				<div class={style.list}>
					{ geoareasB.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>C</h3>
				<div class={style.list}>
					{ geoareasC.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>D</h3>
				<div class={style.list}>
					{ geoareasD.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>E</h3>
				<div class={style.list}>
					{ geoareasE.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>F</h3>
				<div class={style.list}>
					{ geoareasF.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>G</h3>
				<div class={style.list}>
					{ geoareasG.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>H</h3>
				<div class={style.list}>
					{ geoareasH.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>I</h3>
				<div class={style.list}>
					{ geoareasI.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>J</h3>
				<div class={style.list}>
					{ geoareasJ.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>K</h3>
				<div class={style.list}>
					{ geoareasK.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>L</h3>
				<div class={style.list}>
					{ geoareasL.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>M</h3>
				<div class={style.list}>
					{ geoareasM.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>N</h3>
				<div class={style.list}>
					{ geoareasN.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>O</h3>
				<div class={style.list}>
					{ geoareasO.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>P</h3>
				<div class={style.list}>
					{ geoareasP.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Q</h3>
				<div class={style.list}>
					{ geoareasQ.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>R</h3>
				<div class={style.list}>
					{ geoareasR.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>S</h3>
				<div class={style.list}>
					{ geoareasS.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>T</h3>
				<div class={style.list}>
					{ geoareasT.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>U</h3>
				<div class={style.list}>
					{ geoareasU.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>V</h3>
				<div class={style.list}>
					{ geoareasV.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>W</h3>
				<div class={style.list}>
					{ geoareasW.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>X</h3>
				<div class={style.list}>
					{ geoareasX.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Y</h3>
				<div class={style.list}>
					{ geoareasY.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Z</h3>
				<div class={style.list}>
					{ geoareasZ.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Å</h3>
				<div class={style.list}>
					{ geoareasÅ.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Ä</h3>
				<div class={style.list}>
					{ geoareasÄ.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Ö</h3>
				<div class={style.list}>
					{ geoareasÖ.map((geoarea) => {
						return this.getGeoareasItem(geoarea)
					})}
				</div>
			</div>
			</div>
			</div>
			)
	}
}
