import { h, Component } from "preact";
import { route } from "preact-router";
import Markdown from "preact-markdown";

import classNames from "classnames";

import SearchBox from "../../components/SearchBox/SearchBox";
import { MapIcon } from "../../components/SVGIcons";

import style from "./FirstPage.css";

import FirstFooter from "../../components/Footer/FirstFooter";
import { get } from "../../api";

export default class FirstPage extends Component {
  state = {
    content: "",
    lbus: [],
    lbu: undefined,
    searchMode: "default", // 'default', 'distance', 'webshops'
    webshops: false,
    combined: false
  };

  componentDidMount() {
    const mylbs = localStorage.getItem('mylbs')
    if (mylbs) {
      const parsed = JSON.parse(mylbs)
      if (parsed.hasOwnProperty('combined') && parsed.combined) this.setState({combined: true})      
    }
    get("/cms/first-page").then(res => this.setState({ content: res.html }));

  }

  setMode = mode => {
    route("/" + mode + "/" + this.props.query + location.search);
  };

  doSearch = query => {
    if (this.state.combined) return route("/list/" + query + '?searchMode=combined');
    route("/list/" + query);
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  handleCombinedChange = () => {
    localStorage.setItem('mylbs', JSON.stringify({combined: !this.state.combined}))
    this.setState({combined: !this.state.combined})
  }

  render() {
    const { mode, query } = this.props;
    const { bbox, preview, lbu, searchMode = "default" } = this.state;
    let lbus = this.state.lbus;

    // Aargh, needed to prevent input from scrolling too high up
    // on iOS when being focused
    let isIos = false;
    if (typeof window !== "undefined") {
      isIos =
        /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;
    }

    return (
      <div
        class={classNames(
          style.FirstPage,
          isIos ? style.iOS : "",
          this.state.focused ? style.focused : ""
        )}
      >
        <img class={style.Logo} src="/assets/mylbs-badge-white.svg" />
        <div class={style.main}>
          <h2 class={style.keywords}>
            <span onClick={() => route("/products")}>Produkter</span>,{" "}
            <span onClick={() => route("/brands")}>Varumärke</span>,{" "}
            <span onClick={() => route("/geoareas")}>Område</span>
          </h2>
          <div class={style.searchFormDiv}>
            <SearchBox
              query=""
              onChange={this.doSearch}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
          </div>
          <div class={style.checkbox}>
            <form>
              <label>
                Inkludera även E-handel:
                <span class="checkbox">
                  <input
                    name="webshop"
                    type="checkbox"
                    checked={this.state.combined}
                    onChange={this.handleCombinedChange} />
                </span>
              </label>
            </form>
          </div>

          <a href="javascript:void(0)" onClick={() => route("/map")}>
            <MapIcon /> Karta
          </a>
          <div
            class={style.content}
            dangerouslySetInnerHTML={{
              __html: this.state.content
            }}
          />
        </div>
        <div class={style.Footer}>
          <FirstFooter mode={mode} onChange={this.setMode} />
        </div>
      </div>
    );
  }
}
