/* global google */

export default function (map) {

	let svg, circle, circle2, triangle, timer = null

	let alpha = 0

	let coords = null

	const overlay = new google.maps.OverlayView()

	const onSuccess = (pos) => {
		if (pos && pos.coords) {
			coords = pos.coords
		}
		//map.setCenter(new google.maps.LatLng({ lat: coords.latitude, lng: coords.longitude}))
		//map.setZoom(12)
		overlay.draw()
	}

	const onError = (err) => {
		console.warn('ERROR(' + err.code + '): ' + err.message)
	}

	const handleOrientation = (e) => {
		if (e.webkitCompassHeading) {
      // Apple works only with this, alpha doesn't work
      alpha = 360 - event.webkitCompassHeading;
    } else if (e.alpha) {
			alpha = e.alpha
		}
		//console.log("Alpha: ", alpha, e)
		overlay.draw()
	}

	overlay.onAdd = () => {
		timer = navigator.geolocation.watchPosition(onSuccess, onError, {
		  enableHighAccuracy: true,
		  timeout: 5000,
		  maximumAge: 0
		})

		if ('ondeviceorientationabsolute' in window) {
		  // We can listen for the new deviceorientationabsolute event.
			window.addEventListener("deviceorientationabsolute", handleOrientation, true)
		} else if ('ondeviceorientation' in window) {
		  // We can still listen for deviceorientation events.
		  // The `absolute` property of the event tells us whether
		  // or not the degrees are absolute.
			window.addEventListener("deviceorientation", handleOrientation, true)
		}
	}

	overlay.onRemove = () => {
		navigator.geolocation.clearWatch(timer)
		window.removeEventListener("deviceorientationabsolute", handleOrientation, true)
		window.removeEventListener("deviceorientation", handleOrientation, true)
		svg.parentNode.removeChild(svg)
		svg = null
	}

	overlay.draw = () => {
		if (!coords) {
			return
		}

		const latlng = new google.maps.LatLng({
			lat: coords.latitude,
			lng: coords.longitude
		})

		const moved = google.maps.geometry.spherical.computeOffset(latlng, coords.accuracy, 90)

		const px = overlay.getProjection().fromLatLngToDivPixel(latlng)
		const px2 = overlay.getProjection().fromLatLngToDivPixel(moved)
		const radius = Math.max(15, px2.x - px.x)

		if (!svg) {
			svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
			//svg.setAttribute('viewBox', '0 0 200 200')
			svg.style.position = 'absolute'
			svg.style.overflow = 'visible'

			circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
			circle.setAttribute('cx', 0)
			circle.setAttribute('cy', 0)
			circle.setAttribute('fill', 'rgba(0, 0, 255, 0.15)')
			circle.setAttribute('r', radius)
			svg.appendChild(circle)

			triangle = document.createElementNS('http://www.w3.org/2000/svg', 'polygon')
			triangle.setAttribute('points', '-10,0 10,0 0,-20')
			triangle.setAttribute('fill', 'blue')
			svg.append(triangle)

			circle2 = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
			circle2.setAttribute('cx', 0)
			circle2.setAttribute('cy', 0)
			circle2.setAttribute('r', 10)
			circle2.setAttribute('fill', 'blue')
			circle2.setAttribute('stroke', 'white')
			circle2.setAttribute('stroke-width', 2)
			svg.append(circle2)

			overlay.getPanes().overlayImage.appendChild(svg)
		}

		circle.setAttribute('r', radius)
		triangle.style.transform = 'rotate(-' + alpha + 'deg)'

		svg.style.left = px.x + 'px'
		svg.style.top = px.y + 'px'
	}

	return overlay
}
