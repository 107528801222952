import { h, Component } from 'preact'
import { route } from 'preact-router'

import classNames from 'classnames'
import { get } from '../../api'

import {BackArrow} from '../../components/SVGIcons'

import style from './BrandsPage.css'
import Header from '../../components/Header/Header'

export default class BrandsPage extends Component {


	state = {
		"brands*": [],
		"brandsA": [],
		"brandsB": [],
		"brandsC": [],
		"brandsD": [],
		"brandsE": [],
		"brandsF": [],
		"brandsG": [],
		"brandsH": [],
		"brandsI": [],
		"brandsJ": [],
		"brandsK": [],
		"brandsL": [],
		"brandsM": [],
		"brandsN": [],
		"brandsO": [],
		"brandsP": [],
		"brandsQ": [],
		"brandsR": [],
		"brandsS": [],
		"brandsT": [],
		"brandsU": [],
		"brandsV": [],
		"brandsW": [],
		"brandsX": [],
		"brandsY": [],
		"brandsZ": [],
		"brandsÅ": [],
		"brandsÄ": [],
		"brandsÖ": []

	}

	componentDidMount() {
		this.fetchBrands()
	}



	fetchBrands = () => {
		get('/brands').then(brands => this.setState({
			"brands*": brands["*"],
			"brandsA": brands["a"],
			"brandsB": brands["b"],
			"brandsC": brands["c"],
			"brandsD": brands["d"],
			"brandsE": brands["e"],
			"brandsF": brands["f"],
			"brandsG": brands["g"],
			"brandsH": brands["h"],
			"brandsI": brands["i"],
			"brandsJ": brands["j"],
			"brandsK": brands["k"],
			"brandsL": brands["l"],
			"brandsM": brands["m"],
			"brandsN": brands["n"],
			"brandsO": brands["o"],
			"brandsP": brands["p"],
			"brandsQ": brands["q"],
			"brandsR": brands["r"],
			"brandsS": brands["s"],
			"brandsT": brands["t"],
			"brandsU": brands["u"],
			"brandsV": brands["v"],
			"brandsW": brands["w"],
			"brandsX": brands["x"],
			"brandsY": brands["y"],
			"brandsZ": brands["z"],
			"brandsÅ": brands["å"],
			"brandsÄ": brands["ä"],
			"brandsÖ": brands["ö"]
		}))
	}

	getBrandsItem = (brand) => {
		return (
			<p>
				<a href={this.searchBrand(brand)}>{this.setCapitalFirst(brand)}</a>
			</p>
		)
	}

	setCapitalFirst = (word) => {
		return word[0].toUpperCase() + word.substr(1);
	}

	searchBrand = (brand) => {
		return '/list/' + encodeURIComponent(brand);
	}

	render() {

		let brands_ = this.state["brands*"]
		let brandsA = this.state["brandsA"]
		let brandsB = this.state["brandsB"]
		let brandsC = this.state["brandsC"]
		let brandsD = this.state["brandsD"]
		let brandsE = this.state["brandsE"]
		let brandsF = this.state["brandsF"]
		let brandsG = this.state["brandsG"]
		let brandsH = this.state["brandsH"]
		let brandsI = this.state["brandsI"]
		let brandsJ = this.state["brandsJ"]
		let brandsK = this.state["brandsK"]
		let brandsL = this.state["brandsL"]
		let brandsM = this.state["brandsM"]
		let brandsN = this.state["brandsN"]
		let brandsO = this.state["brandsO"]
		let brandsP = this.state["brandsP"]
		let brandsQ = this.state["brandsQ"]
		let brandsR = this.state["brandsR"]
		let brandsS = this.state["brandsS"]
		let brandsT = this.state["brandsT"]
		let brandsU = this.state["brandsU"]
		let brandsV = this.state["brandsV"]
		let brandsW = this.state["brandsW"]
		let brandsX = this.state["brandsX"]
		let brandsY = this.state["brandsY"]
		let brandsZ = this.state["brandsZ"]
		let brandsÅ = this.state["brandsÅ"]
		let brandsÄ = this.state["brandsÄ"]
		let brandsÖ = this.state["brandsÖ"]

		return(
			<div style="overflow:hidden">
			<Header />
			<div class={classNames(style.BrandsPage)}>
			<h1>Hitta produkter från olika varumärken i Stockholm City</h1>
			<h2>Här kan du hitta de butiker som säljer produkterna från de varumärken du letar efter</h2>
			<div class="letter-list">
				<h3>1-9</h3>
				<div class={style.list}>
					{ brands_.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>A</h3>
				<div class={style.list}>
					{ brandsA.map((brand) => {
					return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>B</h3>
				<div class={style.list}>
					{ brandsB.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>C</h3>
				<div class={style.list}>
					{ brandsC.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>D</h3>
				<div class={style.list}>
					{ brandsD.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>E</h3>
				<div class={style.list}>
					{ brandsE.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>F</h3>
				<div class={style.list}>
					{ brandsF.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>G</h3>
				<div class={style.list}>
					{ brandsG.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>H</h3>
				<div class={style.list}>
					{ brandsH.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>I</h3>
				<div class={style.list}>
					{ brandsI.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>J</h3>
				<div class={style.list}>
					{ brandsJ.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>K</h3>
				<div class={style.list}>
					{ brandsK.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>L</h3>
				<div class={style.list}>
					{ brandsL.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>M</h3>
				<div class={style.list}>
					{ brandsM.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>N</h3>
				<div class={style.list}>
					{ brandsN.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>O</h3>
				<div class={style.list}>
					{ brandsO.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>P</h3>
				<div class={style.list}>
					{ brandsP.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Q</h3>
				<div class={style.list}>
					{ brandsQ.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>R</h3>
				<div class={style.list}>
					{ brandsR.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>S</h3>
				<div class={style.list}>
					{ brandsS.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>T</h3>
				<div class={style.list}>
					{ brandsT.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>U</h3>
				<div class={style.list}>
					{ brandsU.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>V</h3>
				<div class={style.list}>
					{ brandsV.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>W</h3>
				<div class={style.list}>
					{ brandsW.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>X</h3>
				<div class={style.list}>
					{ brandsX.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Y</h3>
				<div class={style.list}>
					{ brandsY.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Z</h3>
				<div class={style.list}>
					{ brandsZ.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Å</h3>
				<div class={style.list}>
					{ brandsÅ.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Ä</h3>
				<div class={style.list}>
					{ brandsÄ.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Ö</h3>
				<div class={style.list}>
					{ brandsÖ.map((brand) => {
						return this.getBrandsItem(brand)
					})}
				</div>
			</div>
			</div>
			</div>
			)
	}
}
