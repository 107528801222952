import { h, Component } from 'preact'

import getBriefText from '../../../get-brief-text'
import Image from '../../Image/Image'

import style from './CrawledItem.css'

const areTheSame = (i1, i2) => {
  // Return true if both i1 and i2 are undefined
  // Return false if only i1 or i2 are undefined
  if (!i1 || !i2) {
    return (!i1 && !i2)
  }
  return (
    i1.title === i2.title &&
    i1.url === i2.url &&
    i1.image === i2.image
  )
}

class CrawledItem extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    return !areTheSame(nextProps.item, this.props.item)
  }

  onClick = () => {
    window.open(this.props.item.url, '_blank')
  }

  // getPrice =(item) => { return item.price }
  getPrice = (item) => {
    const price = item.price;
    if (price) {
      if(/^[0-9,.]*$/.test(price) && price != 0 && price != 2017) {
          return price+" SEK";
      }
    }
  }

  render() {
    const { item } = this.props
    
    return (
      <div class={style.card}>
        <li class={style.CrawledItem} onClick={this.onClick}>
          <div>
            <div>
                <h2>{item.title}</h2>
                <p>{getBriefText(item.text)}</p>
                { !(item.image) && (
                <p style="color:grey">{this.getPrice(item)}</p>
            )}
            </div>
            { item.image && (
              <div class={style.image} style="display:block; text-align:center;">
                <Image url={item.image} />
                <p style="color:grey">{this.getPrice(item)}</p>
              </div>
            )}
          </div>
          <div class={style.url}>
                  <a href={item.url} target="_blank" title={item.title}>{decodeURIComponent(item.url)}</a>
                </div>
        </li>
     </div> 
    )
  }

}

export default CrawledItem
