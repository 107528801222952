import { h, Component } from "preact";

import style from "./SitemapItem.css";

class SitemapItem extends Component {


  extractLastWords = (text, url) => {

    const words = text.split(" ")
    var lastPart = url.substring(url.lastIndexOf("/"), url.length)
    var res = ""

    //for (var i = words.length; i--; i > 0 ) {
    for (var i = 0;  i < words.length; i++) {
        if (lastPart.indexOf(words[i]) > -1) {
            if (res.indexOf(words[i]) === -1) {
                res = res + " " + words[i]
            }
        }
    }

    res = res.trim()
    return res.charAt(0).toUpperCase() + res.slice(1)

}

  render() {
    const { item } = this.props;

    let text = this.extractLastWords(item.text, item.url)

    if (text.length < 5) {
        text = item.text.charAt(0).toUpperCase() + item.text.slice(1)
    }

    return (
      <div class={style.card}>
        <li class={style.SitemapItem}>
          <p>{text}</p>
          <div class={style.url}>
            <a href={item.url} target="_blank" title={item.text}>
              {decodeURIComponent(item.url)}
            </a>
          </div>
        </li>
      </div>
    );
  }
}

export default SitemapItem;
