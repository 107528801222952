import { h, Component } from "preact";
import classNames from "classnames";

import { get } from "../../api";

import { SearchIcon, CloseIcon } from "../SVGIcons";

import style from "./SearchBox.css";

export default class SearchBox extends Component {
  state = {
    items: [],
    selectedIndex: -1,
    query: this.props.query
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.query !== this.props.query) {
      this.setState({ query: this.props.query });
    }
  }

  onSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    this.closeSuggest();
    this.input.blur();
    this.props.onChange(this.state.query);
  };

  clear = () => {
    this.setState({ query: "" });
    this.props.onChange("");
    this.closeSuggest();
  };

  onFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  onBlur = () => {
    setTimeout(() => {
      this.closeSuggest();
      if (this.props.onBlur) {
        this.props.onBlur();
      }
    }, 300);
  };

  closeSuggest = () => {
    this.setState({ items: [], selectedIndex: -1 });
  };

  doSuggest = query => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (query.length < 2) {
        return this.setState({ items: [] });
      }
      get("/search/suggest", {
        q: query
      })
        .then(items => {
          this.setState({ items });
        })
        .catch(err => {
          alert(err.message);
        });
    }, 300);
  };

  onInput = e => {
    if (["ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight"].includes(e.key)) {
      return;
    }
    if (e.target.value !== this.state.query) {
      this.doSuggest(e.target.value);
    }

    this.setState({ query: e.target.value });
  };

  onKeyDown = e => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      this.walkSuggest(1);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      this.walkSuggest(-1);
    }
  };

  walkSuggest = step => {
    const { items = [], selectedIndex = -1 } = this.state;
    if (items.length === 0) {
      return;
    }
    let nextIndex = selectedIndex + step;
    if (nextIndex > items.length - 1) {
      nextIndex = 0;
    } else if (nextIndex < 0) {
      nextIndex = items.length - 1;
    }
    this.setState({
      query: items[nextIndex],
      selectedIndex: nextIndex
    });
  };

  selectItem = item => {
    this.setState({ query: item }, () => {
      this.closeSuggest();
      this.onSubmit();
    });
  };

  render() {
    const { query = "" } = this.state;

    const { items = [], selectedIndex = -1 } = this.state;

    return (
      <form class={style.SearchBox} onSubmit={this.onSubmit}>
        <input
          ref={input => (this.input = input)}
          type="search"
          placeholder="Produkt, varumärke, butiksnamn..."
          value={query}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          onInput={this.onInput}
        />
        {query && (
          <div class={style.closeButton} onClick={this.clear}>
            <CloseIcon />
          </div>
        )}
        <div class={style.searchButton} onClick={this.onSubmit}>
          <SearchIcon />
        </div>
        {items.length > 0 && (
          <ul class={style.suggest}>
            {items.map((item, index) => {
              return (
                <li
                  key={index}
                  class={index === selectedIndex ? style.selected : ""}
                  onClick={() => this.selectItem(item)}
                >
                  {item}
                </li>
              );
            })}
          </ul>
        )}
      </form>
    );
  }
}
//<div><i class="fa fa-search"></i></div>
