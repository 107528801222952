const scripts = {}

const loadScript = (src) => {
	if (!scripts[src]) {
		scripts[src] = new Promise((resolve, reject) => {
			const script = document.createElement('script')
			script.src = src
			script.onload = () => resolve()
			script.onerror = (err) => reject(err)
			document.getElementsByTagName('head')[0].appendChild(script)
		})
	}
	return scripts[src]
}

export default loadScript