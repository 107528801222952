import { h, Component } from 'preact'
import { route } from 'preact-router'

import classNames from 'classnames'
import { get } from '../../api'

import {BackArrow} from '../../components/SVGIcons'

import style from './ProductsPage.css'
import Header from '../../components/Header/Header'

export default class ProductsPage extends Component {
state = {
		"products*": [],
		"productsA": [],
		"productsB": [],
		"productsC": [],
		"productsD": [],
		"productsE": [],
		"productsF": [],
		"productsG": [],
		"productsH": [],
		"productsI": [],
		"productsJ": [],
		"productsK": [],
		"productsL": [],
		"productsM": [],
		"productsN": [],
		"productsO": [],
		"productsP": [],
		"productsQ": [],
		"productsR": [],
		"productsS": [],
		"productsT": [],
		"productsU": [],
		"productsV": [],
		"productsW": [],
		"productsX": [],
		"productsY": [],
		"productsZ": [],
		"productsÅ": [],
		"productsÄ": [],
		"productsÖ": []

	}

	componentDidMount() {
		this.fetchProducts()
	}



	fetchProducts = () => {
		get('/products').then(products => this.setState({
			"products*": products["*"],
			"productsA": products["a"],
			"productsB": products["b"],
			"productsC": products["c"],
			"productsD": products["d"],
			"productsE": products["e"],
			"productsF": products["f"],
			"productsG": products["g"],
			"productsH": products["h"],
			"productsI": products["i"],
			"productsJ": products["j"],
			"productsK": products["k"],
			"productsL": products["l"],
			"productsM": products["m"],
			"productsN": products["n"],
			"productsO": products["o"],
			"productsP": products["p"],
			"productsQ": products["q"],
			"productsR": products["r"],
			"productsS": products["s"],
			"productsT": products["t"],
			"productsU": products["u"],
			"productsV": products["v"],
			"productsW": products["w"],
			"productsX": products["x"],
			"productsY": products["y"],
			"productsZ": products["z"],
			"productsÅ": products["å"],
			"productsÄ": products["ä"],
			"productsÖ": products["ö"]
		}))
	}

	getProductsItem = (product) => {
		return (
			<p>
				<a href={this.searchProduct(product)}>{this.setCapitalFirst(product)}</a>
			</p>
		)
	}

	setCapitalFirst = (word) => {
		return word[0].toUpperCase() + word.substr(1);
	}

	searchProduct = (product) => {
		return '/list/' + encodeURIComponent(product);
	}

	render() {

		let products_ = this.state["products*"]
		let productsA = this.state["productsA"]
		let productsB = this.state["productsB"]
		let productsC = this.state["productsC"]
		let productsD = this.state["productsD"]
		let productsE = this.state["productsE"]
		let productsF = this.state["productsF"]
		let productsG = this.state["productsG"]
		let productsH = this.state["productsH"]
		let productsI = this.state["productsI"]
		let productsJ = this.state["productsJ"]
		let productsK = this.state["productsK"]
		let productsL = this.state["productsL"]
		let productsM = this.state["productsM"]
		let productsN = this.state["productsN"]
		let productsO = this.state["productsO"]
		let productsP = this.state["productsP"]
		let productsQ = this.state["productsQ"]
		let productsR = this.state["productsR"]
		let productsS = this.state["productsS"]
		let productsT = this.state["productsT"]
		let productsU = this.state["productsU"]
		let productsV = this.state["productsV"]
		let productsW = this.state["productsW"]
		let productsX = this.state["productsX"]
		let productsY = this.state["productsY"]
		let productsZ = this.state["productsZ"]
		let productsÅ = this.state["productsÅ"]
		let productsÄ = this.state["productsÄ"]
		let productsÖ = this.state["productsÖ"]

		return(
			<div style="overflow:hidden">
			<Header />
			<div class={classNames(style.ProductsPage)}>
			<h1>Hitta produkter från olika varumärken i Stockholm City</h1>
			<h2>Här kan du hitta de butiker som säljer produkterna från de varumärken du letar efter</h2>
			<div class="letter-list">
				<h3>1-9</h3>
				<div class={style.list}>
					{ products_.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>A</h3>
				<div class={style.list}>
					{ productsA.map((product) => {
					return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>B</h3>
				<div class={style.list}>
					{ productsB.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>C</h3>
				<div class={style.list}>
					{ productsC.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>D</h3>
				<div class={style.list}>
					{ productsD.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>E</h3>
				<div class={style.list}>
					{ productsE.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>F</h3>
				<div class={style.list}>
					{ productsF.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>G</h3>
				<div class={style.list}>
					{ productsG.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>H</h3>
				<div class={style.list}>
					{ productsH.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>I</h3>
				<div class={style.list}>
					{ productsI.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>J</h3>
				<div class={style.list}>
					{ productsJ.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>K</h3>
				<div class={style.list}>
					{ productsK.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>L</h3>
				<div class={style.list}>
					{ productsL.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>M</h3>
				<div class={style.list}>
					{ productsM.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>N</h3>
				<div class={style.list}>
					{ productsN.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>O</h3>
				<div class={style.list}>
					{ productsO.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>P</h3>
				<div class={style.list}>
					{ productsP.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Q</h3>
				<div class={style.list}>
					{ productsQ.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>R</h3>
				<div class={style.list}>
					{ productsR.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>S</h3>
				<div class={style.list}>
					{ productsS.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>T</h3>
				<div class={style.list}>
					{ productsT.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>U</h3>
				<div class={style.list}>
					{ productsU.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>V</h3>
				<div class={style.list}>
					{ productsV.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>W</h3>
				<div class={style.list}>
					{ productsW.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>X</h3>
				<div class={style.list}>
					{ productsX.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Y</h3>
				<div class={style.list}>
					{ productsY.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Z</h3>
				<div class={style.list}>
					{ productsZ.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Å</h3>
				<div class={style.list}>
					{ productsÅ.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Ä</h3>
				<div class={style.list}>
					{ productsÄ.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			<div class="letter-list">
				<h3>Ö</h3>
				<div class={style.list}>
					{ productsÖ.map((product) => {
						return this.getProductsItem(product)
					})}
				</div>
			</div>
			</div>
			</div>
			)
	}
}
