import { h, Component } from 'preact'
import { route } from 'preact-router'

import classNames from 'classnames'

import style from './Footer.css'

import { HomeIcon, ListIcon, MapIcon } from '../SVGIcons'

export default class Footer extends Component {

	setMode = (mode) => () => {
		if (mode !== this.props.mode) {
			this.props.onChange(mode)
		}
	}

	render() {

		const { mode } = this.props

		return (
			<div class={style.Footer}>
				<div
					onClick={() => { route('/') }}>
					<HomeIcon />
					Start
				</div>
				<div
					class={mode === 'list' ? style.selected : null}
					onClick={this.setMode('list')}>
					<ListIcon />
					Lista
				</div>
				<div
					class={mode === 'map' ? style.selected : null}
					onClick={this.setMode('map')}>
					<MapIcon />
					Karta
				</div>
			</div>
		)
	}
}
