export default (lbus1, lbus2) => {
  if (!lbus1 && !lbus2) {
    return true;
  }
  if (lbus1 && !lbus2) {
    return false;
  }
  if (!lbus1 && lbus2) {
    return false;
  }
  if (lbus1.length !== lbus2.length) {
    return false;
  }
  return lbus1.every((lbu, index) => {
    return lbu.id === lbus2[index].id;
  });
};
